import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Project.css";

var pData = require("../projects.json");

const Project = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      // slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
      // slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      // slidesToSlide: 1 // optional, default to 1.
    },
  };

  return (
    <div>
      <div className="portfolio">
        <div className="row g-0 mb-3 flex flex-col items-center justify-center gap-2 text-center">
          <div className="col-12 col-md-6">
            <h1 className="text-3xl font-bold our">Our Projects</h1>
          </div>
          <div className="col-12 col-md-6 pt-2 ">
            <p>
              All our projects are unique and designed to last. Take a look at
              our recent works to find it out for yourself.
            </p>
          </div>
          
        </div>
        <div className="row g-2">
          <Carousel
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={2000}
            infinite
            className="car "
          >
            {pData.map((item) => {
              return (
                <div className="one-box flex flex-col items-center justify-center  h-96 w-auto" key={item.id}>
                  <img className="h-60 w-80 object-cover overflow-hidden"  src={item.projectImg}/>
                 
                </div>
              );
            })}
          </Carousel>
          
        </div>
      </div>
    </div>
  );
};

export default Project;
