import React from "react";
import "./product.css";
import product1 from '../../../src/images/Fume hood.jpg';
import product2 from '../../../src/images/extraction system.jpeg';
import product3 from '../../../src/images/titration unit.jpg';
import product4 from '../../../src/images/mobile cupboards.jpeg';
import product5 from '../../../src/images/storage cupboards.jpeg';
import product6 from '../../../src/images/anti-vibration.jpeg';
import product7 from '../../../src/images/reagent rack.jpeg';
import product8 from '../../../src/images/lab chair.jpeg';
import product9 from '../../../src/images/instrument chair.jpeg';
import product10 from '../../../src/images/wall cupboards.jpeg';
import product11 from '../../../src/images/vert stor cabi.jpeg';
import product12 from '../../../src/images/Apron-Cabinet-Manufacturer.jpg';
import product13 from '../../../src/images/Exhaust Blower.jpg';
import product14 from '../../../src/images/lockers.jpg';
import product15 from '../../../src/images/chemis.jpeg';
import product16 from '../../../src/images/th (3).jpeg';
import product17 from '../../../src/images/th (4).jpeg';
import product18 from '../../../src/images/th (5).jpeg';
import product19 from '../../../src/images/OIP (3).jpeg';
import product20 from '../../../src/images/download (5).jpeg';
import product21 from '../../../src/images/download (6).jpeg';
import product22 from '../../../src/images/download (7).jpeg';
import product23 from '../../../src/images/OIP (4).jpeg';
import product24 from '../../../src/images/OIP (5).jpeg';
import Productnav from "./Productnav";




const Product = () => {
  return (
    <>
      <Productnav />
      <div className="Product-page">
         <h1>Product Lists</h1>
         <div className="card">
          <div className="card-content">
            <h2>Fume Hood</h2>
            <hr />
            <h3>Welcome to Ved Lab Solutions</h3>
            <p>
              A fume chamber, also known as a fume hood, is a ventilated enclosure designed to capture and exhaust hazardous fumes, vapors, and dusts from a workspace. It typically features a movable sash window for access and containment. The air is drawn through the hood and expelled outside via ductwork.
              <br /><br />
               Fume hoods are essential in laboratories to ensure user safety and prevent exposure to toxic substances.
              <br /><br />
              They come in various sizes and designs, including benchtop and walk-in models.
              <br /><br />
               Regular maintenance is crucial to ensure their effectiveness.
              <br /><br />
               Modern fume hoods may include energy-saving features like variable air volume systems.
            </p>
          </div>
          <div className="card-image">
            <img src={product1} alt="product-image" />
          </div>
         </div>
         <div className="card">
        <div className="card-content">
          <h2>Extraction system</h2>
          <hr />
          <h3>Welcome to Ved Lab Solutions</h3>
          <p>
          An extraction system in a laboratory is a crucial setup designed to remove harmful airborne contaminants, such as fumes, vapors, and dust, from the workspace. It typically includes a series of ducts, fans, and filters to capture and expel these pollutants. The system ensures that the air quality within the lab remains safe for personnel.
          <br /><br />
           Extraction systems come in various configurations, including local exhaust ventilation and general exhaust systems.
          <br /><br />
           Regular maintenance and monitoring are essential to ensure their efficiency and effectiveness.
          <br /><br />
           Modern extraction systems may incorporate energy-saving technologies.
          <br /><br />
          Advanced filtration methods enhance performance.
        </p>
        
        </div>
        <div className="card-image">
          <img src={product2} alt="product-image" />
        </div>
         </div>
         <div className="card">
      <div className="card-content">
        <h2>Titrtion Unit</h2>
        <hr />
        <h3>Welcome to Ved Lab Solutions</h3>
        <p>
        A titration unit is a vital laboratory tool used to measure the concentration of an unknown solution by adding a titrant of known concentration until the reaction reaches its endpoint, typically signaled by a color change. This process is crucial in fields such as chemistry, biology, and medicine, where accurate substance analysis is essential.

        <br/> <br/>
        Measures the concentration of an unknown solution using a known titrant.<br/> <br/>
        The reaction endpoint is typically indicated by a color change.<br/> <br/>
        Includes a burette and a flask for precise titrant dispensing and reaction.<br/> <br/>
        Widely used in chemistry, biology, and medicine for accurate analysis.
          </p>
      </div>
      <div className="card-image">
        <img src={product3} alt="product-image" />
      </div>
         </div>
         <div className="card">
    <div className="card-content">
      <h2>Mobile cupboard</h2>
      <hr />
      <h3>Welcome to Ved Lab Solutions</h3>
      <p>
              A mobile cupboard, also known as a mobile storage unit, is a movable enclosure designed to securely store and transport hazardous materials, chemicals, and sensitive items within a workspace. It typically features lockable doors for access and containment. The cupboard is equipped with wheels or casters for easy movement and flexible placement.
              <br /><br />
               Mobile cupboards are essential in laboratories and industrial environments to ensure user safety and prevent exposure to dangerous substances.
              <br /><br />
              They come in various sizes and designs, including fire-resistant and corrosive-resistant models.
              <br /><br />
               Regular maintenance is crucial to ensure their effectiveness.
              <br /><br />
               Modern mobile cupboards may include features like adjustable shelving and built-in ventilation systems.
            </p>
    </div>
    <div className="card-image">
      <img src={product4} alt="product-image" />
    </div>
         </div>
         <div className="card">
         <div className="card-content">
           <h2>Storage cupboard</h2>
           <hr />
           <h3>Welcome to Ved Lab Solutions</h3>
           <p>  
              A storage cupboard, also known as a storage cabinet, is an enclosed unit designed to organize and store various materials, tools, and equipment within a workspace. It typically features adjustable shelves and lockable doors for secure access and containment. The cupboard is constructed from durable materials to withstand daily use.
              <br /><br />
               Storage cupboards are essential in laboratories and industrial environments to keep workspaces organized and materials safely stored.
              <br /><br />
              They come in various sizes and designs, including wall-mounted and floor-standing models.
              <br /><br />
               Regular maintenance is crucial to ensure their effectiveness.
              <br /><br />
               Modern storage cupboards may include features like customizable shelving configurations and corrosion-resistant finishes.
            </p>  
         </div>
         <div className="card-image">
           <img src={product5} alt="product-image" />
         </div>
        </div>
        <div className="card">
       <div className="card-content">
         <h2>Anti-vibration Table</h2>
         <hr />
         <h3>Welcome to Ved Lab Solutions</h3>
         <p>  
              An anti-vibration table, also known as a vibration isolation table, is a specialized work surface designed to minimize vibrations and provide a stable environment for sensitive instruments and equipment. It typically features a solid, heavy top supported by isolation pads or dampers.
              <br /><br />
               Anti-vibration tables are essential in laboratories and research facilities to ensure accurate measurements and prevent interference with delicate instruments.
              <br /><br />
              They come in various sizes and designs, including standalone and benchtop models.
              <br /><br />
               Regular maintenance is crucial to ensure their effectiveness.
              <br /><br />
               Modern anti-vibration tables may include features like adjustable leveling feet and additional damping mechanisms.
           </p>  

       
       </div>
       <div className="card-image">
         <img src={product6} alt="product-image" />
       </div>
        </div>
        <div className="card">
     <div className="card-content">
       <h2>Reagent Rack</h2>
       <hr />
       <h3>Welcome to Ved Lab Solutions</h3>
       <p>  
              A reagent rack, also known as a chemical storage rack, is a shelving unit designed to hold and organize chemical reagents and solutions in a laboratory. It typically features multiple shelves or compartments to accommodate various bottle sizes and types.
              <br /><br />
               Reagent racks are essential in laboratories to ensure chemicals are safely stored and easily accessible during experiments.
              <br /><br />
              They come in various sizes and designs, including benchtop and wall-mounted models.
              <br /><br />
               Regular maintenance is crucial to ensure their effectiveness.
              <br /><br />
               Modern reagent racks may include features like chemical-resistant surfaces and customizable shelf configurations.
            </p>  

     </div>
     <div className="card-image">
       <img src={product7} alt="product-image" />
     </div>
        </div>
        <div className="card">
   <div className="card-content">
     <h2>Lab Chair</h2>
     <hr />
     <h3>Welcome to Ved Lab Solutions</h3>
     <p>  
              A lab chair, also known as a laboratory chair, is a specialized seating option designed for use in laboratory settings, providing comfort and support during long periods of work. It typically features adjustable height, ergonomic design, and durable materials that resist chemical spills.
              <br /><br />
               Lab chairs are essential in laboratories to ensure user comfort and support proper posture during tasks.
              <br /><br />
              They come in various sizes and designs, including stools and chairs with backrests.
              <br /><br />
               Regular maintenance is crucial to ensure their effectiveness.
              <br /><br />
               Modern lab chairs may include features like anti-static materials and wheels for easy mobility.
             </p>  


   </div>
   <div className="card-image">
     <img src={product8} alt="product-image" />
   </div>
        </div>
        <div className="card">
          <div className="card-content">
            <h2>Instrument Chair</h2>
            <hr />
            <h3>Welcome to Ved Lab Solutions</h3>
            <p>  
            An instrument chair, also known as a lab technician chair, is a seating option designed specifically for laboratory technicians, providing ergonomic support during the use of delicate instruments. It typically features height adjustability, a stable base, and cushioning for prolonged comfort.
            <br /><br />
             Instrument chairs are essential in laboratories to ensure technicians can perform detailed work comfortably and effectively.
            <br /><br />
            They come in various sizes and designs, including models with or without armrests.
            <br /><br />
             Regular maintenance is crucial to ensure their effectiveness.
            <br /><br />
             Modern instrument chairs may include features like anti-static upholstery and adjustable back support.
</p>  


          </div>
          <div className="card-image">
            <img src={product9} alt="product-image" />
          </div>
         </div>
         <div className="card">
        <div className="card-content">
          <h2>Wall cupboard</h2>
          <hr />
          <h3>Welcome to Ved Lab Solutions</h3>
          <p>  
          A wall cupboard, also known as a wall-mounted cabinet, is an elevated storage unit designed to maximize vertical space by being mounted on a wall. It typically features adjustable shelves and lockable doors for secure storage.
          <br /><br />
           Wall cupboards are essential in laboratories and workspaces to keep materials organized and off the floor, freeing up valuable surface space.
          <br /><br />
          They come in various sizes and designs, including single or double-door models.
          <br /><br />
           Regular maintenance is crucial to ensure their effectiveness.
          <br /><br />
           Modern wall cupboards may include features like corrosion-resistant materials and adjustable shelving.
</p>  
        </div>
        <div className="card-image">
          <img src={product10} alt="product-image" />
        </div>
         </div>
         <div className="card">
      <div className="card-content">
        <h2>Vertical Storage cabinet</h2>
        <hr />
        <h3>Welcome to Ved Lab Solutions</h3>
        <p>  
              A vertical storage cabinet, also known as a tall storage unit, is an upright enclosure designed to store materials, tools, and equipment in a space-efficient manner. It typically features multiple shelves and lockable doors for secure access.
              <br /><br />
               Vertical storage cabinets are essential in laboratories and workspaces to organize materials while minimizing the footprint.
              <br /><br />
              They come in various sizes and designs, including narrow and wide models.
              <br /><br />
               Regular maintenance is crucial to ensure their effectiveness.
              <br /><br />
               Modern vertical storage cabinets may include features like adjustable shelving and corrosion-resistant finishes.
</p>  

      </div>
      <div className="card-image">
        <img src={product11} alt="product-image" />
      </div>
         </div>
         <div className="card">
    <div className="card-content">
      <h2>Apron Storage Unit</h2>
      <hr />
      <h3>Welcome to Ved Lab Solutions</h3>
      <p>  
      An apron cabinet, also known as an apron storage unit, is a specialized enclosure designed to store protective clothing such as aprons in a clean and organized manner. It typically features a hanging rod and shelves for folded items, along with lockable doors for secure storage.
      <br /><br />
       Apron cabinets are essential in laboratories and industrial environments to ensure that protective gear is readily available and properly maintained.
      <br /><br />
      They come in various sizes and designs, including wall-mounted and freestanding models.
      <br /><br />
       Regular maintenance is crucial to ensure their effectiveness.
      <br /><br />
       Modern apron cabinets may include features like ventilation systems to prevent moisture buildup and corrosion-resistant finishes.
</p>  

    </div>
    <div className="card-image">
      <img src={product12} alt="product-image" />
    </div>
         </div>
         <div className="card">
          <div className="card-content">
            <h2>Exhaust blower</h2>
            <hr />
            <h3>Welcome to Ved Lab Solutions</h3>
            <p>  
              An exhaust blower, also known as an exhaust fan, is a mechanical device designed to remove contaminated air, fumes, and particles from a workspace by expelling them to the outside environment. It typically features a high-powered fan housed in a durable casing, connected to ductwork for efficient air extraction.
              <br /><br />
               Exhaust blowers are essential in laboratories and industrial settings to maintain air quality and ensure the safety of workers.
              <br /><br />
              They come in various sizes and designs, including inline and wall-mounted models.
              <br /><br />
               Regular maintenance is crucial to ensure their effectiveness.
              <br /><br />
               Modern exhaust blowers may include features like variable speed controls and noise reduction mechanisms.
</p>  

          </div>
          <div className="card-image">
            <img src={product13} alt="product-image" />
          </div>
         </div>
         <div className="card">
        <div className="card-content">
          <h2>industrial lockers</h2>
          <hr />
          <h3>Welcome to Ved Lab Solutions</h3>
          <p>  
          Industrial Lockers: Safe and Efficient Storage.
          Industrial lockers serve as secure storage solutions in workplaces. Here’s what you need to know.
            <br /><br />
            Industrial lockers provide workers with personal storage space, commonly found in factories, warehouses, and labs.
            <br /><br />
            Built from solid steel, these lockers withstand tough conditions, featuring reinforced doors.
            <br /><br />
            Versatile options include various widths, depths, and adjustable heights.
            <br /><br />
            Louvers ensure air circulation, coat hooks organize belongings, and legs add stability.
</p>  
        
        </div>
        <div className="card-image">
          <img src={product14} alt="product-image" />
        </div>
         </div>
         <div className="card">
      <div className="card-content">
        <h2>chemical Storage Cabinet</h2>
        <hr />
        <h3>Welcome to Ved Lab Solutions</h3>
        <p>
        Chemical Storage Cabinets: Safeguarding Hazardous Materials
        Chemical storage cabinets play a critical role in managing hazardous substances within workplaces. Here’s what you need to know:
          <br /><br />
          Chemical storage cabinets provide workers with dedicated space for safely storing hazardous materials.
          <br /><br />
          Chemical storage cabinets are constructed from solid steel, ensuring durability even in demanding conditions.
          <br /><br />
          Louvers allow proper air circulation, preventing chemical buildup and maintaining freshness.
          <br /><br />
          Cabinets come with legs, adding stability and preventing direct contact with the floor.

        </p>
      </div>
      <div className="card-image">
        <img src={product15} alt="product-image" />
      </div>
         </div>
         <div className="card">
    <div className="card-content">
      <h2>Shoe Racks</h2>
      <hr />
      <h3>Welcome to Ved Lab Solutions</h3>
      <p>
      Shoe racks typically fit spaces that are 18", 24", or 30" wide.
      These versatile pieces of furniture keep all your pairs of shoes together, promoting tidiness and organization.
        <br /><br />
        The height varies based on the number of shelves you want.
        Two-story shoe racks: Around 17 inches (43 cm).
        <br /><br />
        Three-story racks (wood beds with metal frames): Approximately 33 inches (84 cm).
        <br /><br />
        Ideally, consider the size of the shoes.
        Standard depth: 13 inches (33 cm) works well for both kids and adults.
        <br /><br />
        Ankle or rain boots: Standard shelf height (12 inches or 30 cm).
        Tall knee-high or above-the-knee boots: Utilize top shelves or look for specialized designs. 
      </p>
    </div>
    <div className="card-image">
      <img src={product16} alt="product-image" />
    </div>
         </div>
         <div className="card">
          <div className="card-content">
            <h2>laboratory Stools</h2>
            <hr />
            <h3>Welcome to Ved Lab Solutions</h3>
            <p>
            Laboratory stools play a crucial role in providing comfortable and ergonomic seating for scientists, researchers, and lab personnel. Let’s dive into the specifications and considerations for selecting the right laboratory stool
              <br /><br />
              Laboratory furniture, including stools, should have smooth, non-porous surfaces.
              <br /><br />
              Opt for vinyl or solid materials (such as plastic or sealed wood) that resist chemical absorption.
              <br /><br />
              Avoid natural or synthetic fabric upholstery or mesh, as they are not suitable for lab use.
              <br /><br />
              Dual-wheel nylon casters with polyurethane tread (locking casters optional).
            </p>
          </div>
          <div className="card-image">
            <img src={product17} alt="product-image" />
          </div>
         </div>
         <div className="card">
        <div className="card-content">
          <h2>Work Desk</h2>
          <hr />
          <h3>Welcome to Ved Lab Solutions</h3>
          <p>
          Let’s talk about work desks. Whether you’re setting up a home office, a cozy study nook, or a professional workspace, finding the right desk is essential. Here’s a concise guide to desk dimensions
          <br /><br />
          Your average standard office desk is roughly 30 inches tall. This height provides a comfortable working surface for most people.
          <br /><br />
          Desks typically range from 48 to 72 inches wide. The width depends on the available space and your specific needs.
          <br /><br />
          The standard desk depth dimensions are 24 to 36 inches (or two to three feet) deep. Again, consider your workspace and the tasks you’ll perform.
          <br /><br />
          These can vary based on room layout and personal preferences. A standard built-in desk usually has.
        </p>
        
        </div>
        <div className="card-image">
          <img src={product18} alt="product-image" />
        </div>
         </div>
         <div className="card">
      <div className="card-content">
        <h2>A.A.S. Stainless steel Hoods</h2>
        <hr />
        <h3>Welcome to Ved Lab Solutions</h3>
        <p>
        A.A.S. hoods are constructed from high-quality stainless steel (usually 430-grade) for durability and corrosion resistance.
          <br /><br />
          The exposed parts are typically manufactured from 0.8mm stainless steel thickness, ensuring sturdiness.
          <br /><br />
          These hoods come equipped with grease filters.
          filters are often 16" stainless steel baffle type, which carry UL listings.
          <br /><br />
          A.A.S. hoods are built in accordance with safety standards such as NFPA 96, NSF specifications, and UL listings.
          <br /><br />
          These hoods are suitable for various applications, including commercial kitchens, laboratories, and industrial settings.
        </p>
      </div>
      <div className="card-image">
        <img src={product19} alt="product-image" />
      </div>
         </div>
         <div className="card">
    <div className="card-content">
      <h2>Filing Cabinets</h2>
      <hr />
      <h3>Welcome to Ved Lab Solutions</h3>
      <p>
      Filing cabinets are essential pieces of furniture commonly found in offices, home offices, and other workspaces. They provide organized storage for documents, files, and other important paperwork. Let’s explore the key dimensions and types of filing cabinet
        <br /><br />
        Two-drawer vertical cabinets are typically 29 inches high.
        Three-drawer cabinets are around 40 inches high.
        <br /><br />
        Two-drawer vertical cabinets are typically 29 inches high.
        Three-drawer cabinets are around 40 inches high.
        <br /><br />
        Standard vertical file cabinets are usually 28½ inches deep.
        <br /><br />
        These cabinets consist of shelves with vertical supports.
         Ideal for end tab folders and color-coded organization.
      </p>
    </div>
    <div className="card-image">
      <img src={product20} alt="product-image" />
    </div>
         </div>
         <div className="card">
          <div className="card-content">
            <h2>Cross Over Benches</h2>
            <hr />
            <h3>Welcome to Ved Lab Solutions</h3>
            <p>
            Also known as crossover benches, these nifty creations find their home in cleanrooms, laboratories, and other controlled environments. Their mission? To facilitate personnel movement across clean or sterile areas while doing the contamination tango. 
              <br /><br />
              Simple yet functional. You’ll find seating areas, shoe-cleaning stations, hand-sanitizing dispensers, and maybe even a secret compartment for your lab notebook.
              <br /><br />
              Hand sanitizer dispensers—because clean hands are happy hands.
              <br /><br />
              These benches are like the VIP section for controlled environments—cleanrooms, pharmaceutical production facilities, and research labs.
              <br /><br />
              Some benches even have compartments for personal items, so external contaminants don’t crash the party.
            </p>
          </div>
          <div className="card-image">
            <img src={product21} alt="product-image" />
          </div>
         </div>
         <div className="card">
        <div className="card-content">
          <h2>SS - Trolley</h2>
          <hr />
          <h3>Welcome to Ved Lab Solutions</h3>
          <p>
          An SS laboratory trolley, also known as a stainless steel lab cart, is like the lab’s personal valet. It’s a wheeled platform made from—you guessed it—stainless steel. Why stainless steel? Because it’s durable, corrosion-resistant, and easy to clean. These trolleys are the unsung heroes of lab logistics.
          <br /><br />
        
          Picture a lab bustling with pipettes, microscopes, and lab-coated scientists. SS lab trolleys are the backstage crew, ensuring everything runs smoothly.
          <br /><br />
          These trolleys can be customized to fit specific lab needs. Need an extra shelf? Done. Want a trolley with a built-in Bunsen burner holder? Sure thing!
          <br /><br />
          But wait, there’s more! These trolleys aren’t lab-exclusive. You’ll find them in hospitals, cleanrooms, and even industrial settings.
          <br /><br />
          Stainless steel means no rust, no fuss. These trolleys are ready for the lab’s chemical dance party.
        </p>
        
        </div>
        <div className="card-image">
          <img src={product22} alt="product-image" />
        </div>
         </div>
         <div className="card">
      <div className="card-content">
        <h2>Dust-Bin</h2>
        <hr />
        <h3>Welcome to Ved Lab Solutions</h3>
        <p>
        Picture a compact, convenient, and safe biohazard bin perched on your lab bench. It’s like a tiny guardian for discarded tips, tubes, gloves, and other hazardous bits. With its easy push-open/close spring lid, it ensures safe disposal without compromising your lab’s mojo. And the footprint? A neat 7×5×7 inches—small but mighty! 🔬

          <br /><br />
          These motion-sensitive champs open automatically to accept waste and then close into a fitted well. They’re like the James Bond of waste bins—sleek, efficient, and ready for action. 
          <br /><br />
          
          Bright red and biohazard-ready, this container handles biological materials, broken glass, recyclables, and other waste. It’s like the lab’s superhero cape. 🦠
          <br /><br />
          Red Lining: Biohazard waste. Think “danger, Will Robinson!”
          <br /><br />
          Each has its purpose—yellow for sharps, blue for recyclables, and green for general waste. It’s like a rainbow of responsibility. 
        </p>
      </div>
      <div className="card-image">
        <img src={product23} alt="product-image" />
      </div>
         </div>
         <div className="card">
    <div className="card-content">
      <h2>Peg Boards</h2>
      <hr />
      <h3>Welcome to Ved Lab Solutions</h3>
      <p>
      Imagine a hardboard with a thousand tiny holes, like a celestial map for your tools. It’s most often made from masonite (compressed engineered wood), but you can find steel or plastic versions too. These boards are like the Swiss Army knives of storage—they hold everything from hammers to hairdryers. 
        <br /><br />
        Pegboards let you arrange hooks, shelves, and bins in any configuration. Need a spot for your wrenches? Done. Want to hang your gardening gloves? Easy peasy.
        <br /><br />
        When floor space is scarce, pegboards swoop in like minimalist superheroes. Hang 'em vertically, horizontally, or diagonally—the choice is yours.
        <br /><br />
        Picture your tools lined up like a symphony orchestra—each peg a different instrument. And when you need that Phillips screwdriver, it’s right there, waiting for its solo.
        <br /><br />
        Craft rooms adore pegboards. They hold yarn, scissors, glitter, and dreams. Plus, they’re like mood boards for your creative brain.
      </p>
    </div>
    <div className="card-image">
      <img src={product24} alt="product-image" />
    </div>
         </div>
        
      </div>
    </>
  );
};

export default Product;
