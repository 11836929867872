import React from "react";
import "./navbar.css";
import { FaBars } from "react-icons/fa";
import "react-modern-drawer/dist/index.css";
import Drawerreact from "./Drawerreact";
import { Link } from "react-router-dom";

import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const Navbar = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
 

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  

  const tl = gsap.timeline();

  useGSAP(() => {
    tl.from(".logo", {
      y: 30,
      opacity: 0,
      duration: 0.5,
      delay: 0.5,
    });

    tl.from(".list li", {
      y: 40,
      stagger: 0.1,
      opacity: 0,
    });
    tl.from(".last a", {
      y: 40,
      repeat: 2,
      yoyo: 1,
      opacity: 0,
      stagger: 0.1,
    });
  });

  return (
    <>
      <div className="navbar">
        <FaBars className="menu" onClick={toggleDrawer} />
        <div className="logo">
          <h3>Ved Lab Solutions</h3>
        </div>
        <ul className="list">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li >
            <Link to="/product">Products</Link>
           
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
        <div className="last">
          <a className="a" href="mailto:dm7717007@gmail.com">
            ved-labsol@gmail.com
          </a>
          <a className="a" href="tel:+1234567890">
            1800-145-789
          </a>
        </div>
      </div>

      <div className="drawer">
        <Drawerreact open={isOpen} onClose={toggleDrawer} direction="right" />
      </div>
    </>
  );
};

export default Navbar;
