import React from 'react'
import { RiTeamLine } from "react-icons/ri";
import { FaRegLightbulb } from "react-icons/fa6";
import { FaRegHeart } from "react-icons/fa6";
import './about.css'
import Aboutnav from './aboutnav';
import Secabout from './secabout';
import Dropdown from '../Header/dropdown';

var pData = require("../projects.json")

const About = () => {

   

    return (
        <div className='aboutus'>
        
        <div><Aboutnav/></div>
           <div><Secabout/></div>
         
        </div>
    )
}

export default About