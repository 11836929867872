import React, { useState } from "react";
import pic from "../../images/Businesswoman (1).png";
import './Contsec3.css';

const Contsec3 = () => {
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [resultMessage, setResultMessage] = useState("");
  const [resultClass, setResultClass] = useState("text-gray-400");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const jsonData = JSON.stringify({
      ...formData,
      access_key: "dee078be-15bb-4b10-b361-72a8b89cf3f9",
      subject: "New Submission from Web3Forms",
    });

    setResultMessage("Please wait...");

    fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: jsonData,
    })
      .then(async (response) => {
        const json = await response.json();
        if (response.status === 200) {
          setResultMessage(json.message);
          setResultClass("text-green-500");
        } else {
          setResultMessage(json.meenssage);
          setResultClass("text-red-500");
        }
      })
      .catch(() => {
        setResultMessage("Something went wrong!");
        setResultClass("text-red-500");
      })
      .finally(() => {
        setFormData({
          name: "",
          last_name: "",
          email: "",
          phone: "",
          message: "",
        });
        setTimeout(() => {
          setResultMessage("");
          setResultClass("text-gray-400");
        }, 5000);
      });
  };

  return (
    
      <div className="contect  flex items-center justify-center bg-white  rounded-md shadow-sm">

          <div className="text-center items-center ">
          
            <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-900">
              Contact Us
            </h1>
            <p className="text-gray-400 dark:text-gray-400">
              Fill up the form below to send us a message.
            </p>
            <img src={pic} alt=""/>
          </div>
          <div className="m-7">
            <form onSubmit={handleSubmit}>
              <div className="flex mb-6 space-x-4">
                <div className="w-full md:w-1/2">
                  <label
                    htmlFor="first_name"
                    className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="first_name"
                    placeholder="John"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                  />
                </div>
                <div className="w-full md:w-1/2">
                  <label
                    htmlFor="lname"
                    className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    id="lname"
                    placeholder="Doe"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                  />
                </div>
              </div>

              <div className="flex mb-6 space-x-4">
                <div className="w-full md:w-1/2">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="you@company.com"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                  />
                </div>

                <div className="w-full md:w-1/2">
                  <label
                    htmlFor="phone"
                    className="block text-sm mb-2 text-gray-600 dark:text-gray-400"
                  >
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="+1 (555) 1234-567"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                  />
                </div>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                >
                  Your Message
                </label>
                <textarea
                  rows="5"
                  name="message"
                  id="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                  required
                ></textarea>
              </div>
              <div className="mb-6">
                <button
                  type="submit"
                  className="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none"
                >
                  Send Message
                </button>
              </div>
              <p className={`text-base text-center ${resultClass}`}>
                {resultMessage}
              </p>
            </form>
          
        </div>
        
      </div>
   
  );
};

export default Contsec3;
