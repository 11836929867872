import React from "react";  

import img1 from "../../images/Screenshot 2024-09-10 121515.png"; 
import img2 from "../../images/img lab1.webp";
import img3 from "../../images/img lab2.jpeg";
import img4 from "../../images/img lab3.jpeg";
import './Homesec3.css';


const Homesec3  = () => {  

  

    return (  
        <div className="w-full object-cover mx-auto p-8 yello ">  
            <h1 className="text-center text-3xl font-bold mb-4">Simple, clever storage solutions</h1>  
            <p className="text-center text-lg mb-8">  
            Smart storage solutions are essential not only in our homes but also in specialized environments like laboratories. Just as well-designed shoe racks and jewelry trays enhance daily life, clever storage options in labs contribute to efficiency, safety, and organization.  
            </p>  
            <div className="grid grid-cols-1 md:grid-cols-2  gap-8">  
                <div className="text-center">  
                    <img src={img1} alt="Fitted Wardrobes" className="w-full h-72 mb-4 shadow-md p-4 bg-white rounded-lg "/>  
                    <h2 className="text-xl font-bold  hover:text-yellow-500 hover:shadow-md py-2 px-4 rounded ">Laboratory Canis</h2>  
                   
                    <a href="#" className="text-blue-600" Browse Fitted Wardrobes />  
                </div>  
                <div className="text-center">  
                    <img src={img2} alt="Living Spaces" className="w-full h-72 mb-4 shadow-md p-4 bg-white rounded-lg"/>  
                    <h2 className="text-xl font-bold
                     hover:text-yellow-500 hover:shadow-md py-2 px-4 rounded">Lab Workbenches with Storage</h2>  
                  
                    <a href="#" className="text-blue-600" Browse Living Spaces />
                </div>  
                <div className="text-center">  
                    <img src={img3} alt="Understairs" className="w-full h-72 mb-4 shadow-md p-4 bg-white rounded-lg"/>  
                    <h2 className="text-xl font-bold  hover:text-yellow-500 hover:shadow-md py-2 px-4 rounded ">Mobile Lab Carts</h2> 
                    
                    <a href="#" className="text-blue-600" Browse Understairs /> 
                </div>  
                <div className="text-center">  
                    <img src={img4} alt="Home Office" className="w-full h-72 mb-4 shadow-md p-4 bg-white rounded-lg "/>  
                    <h2 className="text-xl font-bold  hover:text-yellow-500 hover:shadow-md py-2 px-4 rounded ">Shelving Systems:</h2>  
                    
                    <a href="#" className="text-blue-600" Browse Home Office/>
                </div>  
            </div>  
        </div>  
    );  
};  

export default Homesec3; 