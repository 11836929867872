import React from 'react';
import BalconyIcon from '@mui/icons-material/Balcony';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CountUp from 'react-countup';
import './Homesec2.css';
  
  const Homesec2 = () =>  {
	return (
        <div className='about-3'>
      
        <div className='about-3sub'>
           
          <div className='about-item-1'>
            <BalconyIcon className='about-icons'/>
            <h1><CountUp duration={10} end={500} /></h1>
            <h4>+ Successfull year</h4>
            
          </div>
          
          <div className='about-item-2'>
            <GroupAddIcon className='about-icons'/>
            <h1><CountUp duration={15} end={1000} /></h1>
            <h4>+ Happy Clients</h4>
          </div>
          
          <div className='about-item-3'>
            <EngineeringRoundedIcon className='about-icons'/>
            <h1><CountUp duration={12} end={800} /></h1>
            <h4>+ Our equipments</h4>
            
          </div>
          
          <div className='about-item-4'>
            <DoneAllIcon className='about-icons'/>
            <h1><CountUp duration={10} end={1200} /></h1>
            <h4>+ Claint served</h4>
          </div>
       
        </div>
        </div>
    
	);
  }
  
  export default Homesec2;
  