import React from 'react';
import './aboutnav.css'
  
  const Aboutnav = () =>  {
	return (
	  <div>
      <div className='about-container'>
        <h1>About-Us </h1></div>
	  </div>
	);
  }
  
  export default Aboutnav;
  