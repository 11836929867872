import React, { useEffect } from "react";
import "./CursorProvider.css";
import { gsap } from "gsap";

const CursorProvider = ({ children }) => {
  useEffect(() => {
    const handleMouseMove = (event) => {
      const scrollX = window.scrollX || window.pageXOffset;
      const scrollY = window.scrollY || window.pageYOffset;

      gsap.to("#cursor", {
        x: event.clientX + scrollX,
        y: event.clientY + scrollY,
        duration: 0.6, // Adjust duration for stickiness
        ease: "power2.out", // Smooth easing effect
      });
    };

    const handleTouchMove = (event) => {
      const touch = event.touches[0];
      const scrollX = window.scrollX || window.pageXOffset;
      const scrollY = window.scrollY || window.pageYOffset;

      gsap.to("#cursor", {
        x: touch.clientX + scrollX,
        y: touch.clientY + scrollY,
        duration: 0.2, // Adjust duration for stickiness
        ease: "power2.out", // Smooth easing effect
      });
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleTouchMove);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  return (
    <>
      <div
        id="cursor"
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          pointerEvents: "none",
        }}
      ></div>
      {children}
    </>
  );
};

export default CursorProvider;
