import React from 'react'

import './contact.css'

import Contactnav from './Contactnav';
import Contsec from './Contsec';
import Contsec2 from './Contsec2';

const Contact = () => {
    return (
        <>
         <Contactnav/>
         <Contsec2/>
         <Contsec/>
       
        </>
    )
}

export default Contact