import React from "react";
import { RiTeamLine } from "react-icons/ri";
import { FaRegLightbulb } from "react-icons/fa6";
import { FaRegHeart } from "react-icons/fa6";
import "./whychoose.css";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Whychoose = () => {
  useGSAP(() => {
    gsap.from(".pic1", {
      x: 40,
      duration: 1,
      delay: 0.5,
      opacity: 0,
      scrollTrigger: {
        trigger: ".pic1",
        scroller: "Body",

        start: "top 60%",
      },
    });
    gsap.from(".pic2", {
      x: -50,
      duration: 1,
      delay: 0.5,
      opacity: 0,
      scrollTrigger: {
        trigger: ".pic2",
        scroller: "Body",

        start: 550,
      },
    });
    gsap.from(".pic3", {
      y: 50,
      duration: 1,
      opacity: 0,
      delay: 0.5,
      scrollTrigger: {
        trigger: ".pic3",
        scroller: "Body",

        start: 600,
      },
    });
    gsap.from(".left h1 ", {
      y: 50,
      duration: 1,
      opacity: 0,
      delay: 0.5,

      scrollTrigger: {
        trigger: ".left h1 ",
        scroller: "Body",

        start: 600,
      },
    });
    gsap.from(".min h5", {
      x: 100,
      duration: 1,
      opacity: 0,
      stagger: 0.2,
      delay: 0.5,
      scrollTrigger: {
        trigger: "h5",
        scroller: "Body",

        start: 600,
      },
    });
    gsap.from(".min p", {
      y: 50,
      duration: 1,
      opacity: 0,
      stagger: 0.2,
      delay: 0.5,
      scrollTrigger: {
        trigger: "p",
        scroller: "Body",

        start: 600,
      },
    });
    gsap.from(".min .icon", {
      y: 50,
      duration: 1,
      opacity: 0,
      delay: 0.5,
      scrollTrigger: {
        trigger: ".icon",
        scroller: "Body",

        start: 600,
      },
    });
  });
  return (
    <div>
      <div className="au">
        <div className="row g-0">
          <div className="col-md-12 col-lg-6 row g-0 ">
            <div className="col-md-12 pics">
              <img
                className="pic1 pt-4 shadow-md p-4 bg-white rounded-lg"
                src="./Img/image4.jpeg"
                height={400}
                width={250}
              />
              <img
                className="pic2 shadow-md p-4 bg-white rounded-lg"
                src="./Img/image3.jpeg"
                height={250}
                width={300}
              />
            </div>
            <div className="col-md-12 pics2 shadow-md p-4 bg-white rounded-lg ">
              <img className="pic3 p-4 shadow-md p-4 bg-white rounded-lg" src="./Img/image2.jpeg" />
            </div>
          </div>
          <div className="min col-md-12 col-lg-6 left px-4">
            <div>
              <h1 className="text-4xl font-bold">Why Choose Us</h1>
              <p className="pp">
                Besides exceptional laboratory furniture design, there are
                several compelling reasons to choose Interia. Here are some of
                the most noteworthy:
              </p>
            </div>
            <div className="d-box flex-column">
              <div className="d-flex gap-3">
                <div className="">
                  <RiTeamLine className="icon" />
                </div>
                <div className="">
                  <h5 className="font-bold">Professional Team</h5>
                  <p>
                    Our team comprises top-tier decorators and interior
                    designers from the industry. Their expertise ensures
                    excellence in every project.
                  </p>
                </div>
              </div>
              <div className="d-flex gap-3">
                <div className="">
                  <FaRegLightbulb className="icon" />
                </div>
                <div className="">
                  <h5 className="font-bold">Unusual Ideas</h5>
                  <p>
                    At Interia, we thrive on originality. Our designers
                    consistently generate fresh, innovative ideas that perfectly
                    align with your unique requirements.
                  </p>
                </div>
              </div>
              <div className="d-flex gap-3">
                <div className="">
                  <FaRegHeart className="icon" />
                </div>
                <div className="">
                  <h5 className="font-bold">Made with Respect</h5>
                  <p>
                    Respect is the cornerstone of our approach. From the initial
                    consultation to the final execution, we prioritize your
                    vision, provide outstanding service, and infuse creativity
                    into every detail.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whychoose;
