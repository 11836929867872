import React from 'react';
import './Contactnav.css';
  
  const Contactnav = () =>  {
	return (
        <div className="service-1">
        <h1>Contact</h1>
      </div>
	  
	);
  }
  
  export default Contactnav;
  