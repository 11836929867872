import React from 'react';
import './Productnav.css'
  
  const Productnav = () =>  {
	return (
        <div className="service-1">
        <h1>Product</h1>
      </div>
	);
  }
  
  export default Productnav;
  