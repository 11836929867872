import React from 'react';
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { ImCalculator } from "react-icons/im";
import { FaRegStar } from "react-icons/fa6";

import './Contsec.css';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Contsec3 from './Contsec3';

gsap.registerPlugin(ScrollTrigger);
  
  const Contsec = () =>  {
    useGSAP(() => {
        gsap.from(".c-title h1", {
            y: 50,
            duration:1,
            opacity:0,
            delay:1,
            scrollTrigger:{
               trigger:".icon",
               scroller:"Body",
              start: "top 80%",
            }
          })
        gsap.from(".contactus h5", {
            y: 50,
            duration:1,
            opacity:0,
            delay:0.5,
            scrollTrigger:{
               trigger:".icon",
               scroller:"Body",
               start: "top 70%",
            }
           })
        gsap.from(".contag .icon", {
            height:0,
            duration:1,
            opacity:0,
            delay:0.5,
            scrollTrigger:{
               trigger:".icon",
               scroller:"Body",
               start: "top 70%",
            }
          })
          gsap.from(".border-div", {
            height:0,
            duration:1,
            opacity:0,
            delay:0.5,
            scrollTrigger:{
               trigger:".icon",
               scroller:"Body", 
               start: "top 70%",
            }
          })
    })
	return (
	  <div>
      <div className='contactus'>
        
      <div className='c-title'>
          <h1 className='text-3xl font-bold'>Book a free designer</h1>
          <h1>consultation</h1>
          <h5 className='pt-3'>A great way to your new interior</h5>
      </div>
      <div className='contag row g-0'>
          <div className='col-12 col-md-4 c-box'>
              <IoChatbubbleEllipsesOutline className='icon' />
              <h5>Discuss every aspect of your </h5>
              <h5>interior with one of our designers</h5>
              <div className='border-div'>  </div>

          </div>
          <div className='col-12 col-md-4 c-box'>

              <ImCalculator className='icon' />
              <h5>Discuss every aspect of your </h5>
              <h5>interior with one of our designers</h5>
              <div className='border-div'>  </div>
          </div>
          <div className='col-12 col-md-4 c-box'>

              <FaRegStar className='icon' />
              <h5>Discuss every aspect of your </h5>
              <h5>interior with one of our designers</h5>
              <div className='border-div'> </div>

          </div>

      </div>
      <div className='contactform mt-5'>
     <Contsec3/>
        
      </div>
  </div>
	  </div>
	);
  }
  
  export default Contsec;
  