import React from 'react';
import './Contsec2.css';
import MessageIcon from '@mui/icons-material/Message';
import ContactPhoneIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Contsec2 = () => {
  return (
    <div className='mains'>
      <ul>
        <li>
          <MessageIcon />
          <label>Message</label>
        </li>
        <li>
          <ContactPhoneIcon />
          <label>Contact No</label>
          <p>82055554156</p>
        </li>
        <li>
          <EmailIcon />
          <label>Email</label>
          <p>ved-labsol@gmail.com</p>
        </li>
        <li>
          <LocationOnIcon />
          <label>Location</label>
          <p>Vadodara</p>
        </li>
      </ul>
    </div>
  );
};

export default Contsec2;
