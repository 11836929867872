import React from 'react'
import "./secabout.css"
import ab1 from "../../images/home1.jpg"


const Secabout = () => {
  return (
    <div className='sec-container'>
    
    <div className='hero-content'>

    <h2>Ved Lab Solutions </h2><br></br>

    <p> Now modifying your space with the right furniture is easy with the help of Ved Furniture.<br></br><br></br>

    We are experts in designing and developing Chemical Cabinets, Library Furniture, Straight Wall Bench and more which are used in schools, offices, colleges, laboratories etc.<br></br><br></br>
    
    Right from the initialization of the concept though designing, preparing sample and production, our team maintains coordination with the clients.<br></br><br></br>
    
    This practice allows us to accommodate their specific requirements without any hassle.  </p>
     
       

    </div>

    <div className='hero-image'>
    <img src={ab1} alt='photo'/>
    </div>
      
    </div>
  )
}

export default Secabout
