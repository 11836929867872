import {BrowserRouter,Routes,Route} from 'react-router-dom';
import './App.css';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Header/Navbar';
import Home from './Components/Home/Home';

import About from './Components/Home/About';
import Contact from './Components/Home/Contact';
import Product from './Components/Home/product';


import Homesec2 from './Components/Home/Homesec2';
import CursorProvider from './Components/Cursor/CursorProvider';


function App() {
  return (
    <>
      <CursorProvider>
      <BrowserRouter>
      <Navbar/>
        <Routes>
          <Route path='/' element ={<Home/>} />
          
          <Route path='/about' element ={<About/>} />
          <Route path='/contact' element ={<Contact/>} />
          <Route path='/product' element={<Product/>} />
         
          <Route path='/homesec' element={<Homesec2/>}/>
        </Routes>
      <Footer/>
      </BrowserRouter>
      </CursorProvider>
    </>
  );
}

export default App;
