import React, { } from "react";
import "./home.css";

import { Link } from "react-router-dom";
import Project from "../Project/Project";
import Whychoose from "./whychoose";
import Contsec from "./Contsec";

import Homesec2 from "./Homesec2";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Homesec3 from "./Homesec3";

const Home = () => {
  

  

  const ld = gsap.timeline()

  useGSAP(()=>{
    ld.from(".h-text h1", {
      x:500,
      stagger:0.1,
     opacity:0
    
    })
    ld.from(".h-text p", {
      x:-100,
      opacity:0,
     
    })
    ld.from(".h-text .c-btn", {
      x:500,
      opacity:0
    })
  })
  

  return (
    <>
       
      <div className="main">
      
        <div className="h-text">
          <h1>Interior design</h1>
          <h1 >and decore</h1>
          <h1 >for your laboratory</h1>
          <br></br>
          <p >
          “Our team specializes in creating ergonomic and functional spaces for our clients. Since 1999, we’ve been translating innovative ideas into practical solutions for laboratory furniture.”
          </p>
          <br></br>
          <Link className="clink" to="/contact">
            
            <div className={"c-btn mt-3  "}>
              Contact Us
            </div>
          </Link>
        </div>
      </div>
      <Homesec2/>
      
      <Whychoose />
      <Homesec3/>
      <Contsec />
      <Project />
    </>
  );
};

export default Home;
